import React, {useState} from 'react';
import {Popup, RadioButton} from '@partssourceinc/react-ui-core';
import 'less/AdvancedLists/privacyPillDialog.less';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Select from 'react-select'

export default function PrivacyPillDialog({onCancel, onConfirm}) {

    const [company, setCompany] = useState(null);
    const [facilityOptions, setFacilityOptions] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedPrivacy, setSelectedPrivacy] = useState('0');

    const onSave = () => {
        let companyList = [];
        let facilityList = [];
        let groupList = [];

        switch (selectedPrivacy) {
            case '0':
                companyList.push({id: parseInt(company.facilityId), name: company.facilityName.replace(`${company.facilityId} - `, '')});
                break;
            case '1':
                facilities.forEach(f => {
                    facilityList.push({id: parseInt(f.facilityId), name: f.facilityName})
                });
                break;
            case '2':
                groups.forEach(g => groupList.push(
                    {
                        groupCompanyId: parseInt(company.facilityId),
                        companyName: company.facilityName.replace(`${company.facilityId} - `, ''),
                        groupId: g.groupId,
                        groupName: g.displayName,
                    }));
               
                break;
        }

        onConfirm(companyList, facilityList, groupList);
    };

    const getCompanies = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }

        axios.get(`/SettingsService/api/v1/facility/search/${inputValue}/true`).then(x => {
            return callback(x.data);
        });
    };

    const getFacilities = (facilityId) => {
        axios.get(`/SettingsService/api/v1/companyHierarchy/${facilityId}`).then(x => {
            setFacilityOptions(_.orderBy(x.data, ['facilityName'], ['asc']));
        });
    };

    const getGroupOptions = (facilityId) => {

        axios.get(`/SettingsService/api/v1/groups/${facilityId}`).then(x => {
            setGroupOptions(_.orderBy(x.data.groups, ['displayName'], ['asc']));
        });
    };

    const onCompanyChange = (value) => {
        setCompany(value);
        if (value === null) {
            setFacilities([]);
            setGroups([]);
        } else {
            getFacilities(value.facilityId);
            getGroupOptions(value.facilityId);
        }
    };

    const onFacilityChange = (values) => {
        setFacilities(values);
    };

    const onGroupChange = (values) => {
        setGroups(values);
    };

    const disableConfirm = () => {
        switch (selectedPrivacy) {
            case '0':
                return company == null;
            case '1':
                return !(facilities && facilities.length > 0);
            case '2':
                return !(groups && groups.length > 0);
        }
    };

    return (<Popup
        className="privacy-pill-dialog"
        show={true}
        disableClose={false}
        onCancel={onCancel}
        onConfirm={onSave}
        cancelText="CANCEL"
        disableConfirm={disableConfirm()}
        confirmText="SAVE">
        <div>
            <h1 style={{fontSize: '24px'}}>Add Privacy Filter</h1>
            <div className="create-advanced-list" style={{alignItems: 'baseline', marginTop: '20px'}}>
                {privacyOptions.map((item, i) => (
                    <RadioButton
                        key={i}
                        id={`privacy-option-${i}`}
                        className="list-radio"
                        value={item.id}
                        onChange={(e) => setSelectedPrivacy(e.target.value)}
                        checked={item.id === selectedPrivacy}
                    >
                        <span className="list-item-title">{item.label}</span>
                        <div>
                            <span className="list-item-description">{item.description}</span>
                        </div>
                    </RadioButton>
                ))}
            </div>
            <AsyncSelect isMulti={false} cacheOptions={true} loadOptions={getCompanies} getOptionValue={option => option.facilityId}
                getOptionLabel={option => option.facilityName} noOptionsMessage={() => null} placeholder="Company" isClearable={true}
                classNamePrefix="select" value={company} onChange={onCompanyChange} className={'custom-multi-select'} />
            {selectedPrivacy === '1' && <Select isMulti={true} cacheOptions={true} options={facilityOptions} getOptionValue={option => option.facilityId}
                getOptionLabel={option => option.facilityName} noOptionsMessage={() => null} placeholder="Facility" isClearable={true}
                classNamePrefix="select" value={facilities} onChange={onFacilityChange} className={'custom-multi-select'} isDisabled={company === null} />}
            {selectedPrivacy === '2' && <Select isMulti={true} cacheOptions={true} options={groupOptions} getOptionValue={option => option.groupId}
                getOptionLabel={option => option.displayName} noOptionsMessage={() => null} placeholder="Group" isClearable={true}
                classNamePrefix="select" value={groups} onChange={onGroupChange} className={'custom-multi-select'} isDisabled={company === null} />}
        </div>
    </Popup>)
}

const privacyOptions = [
    {
        id: '0',
        label: 'By Company',
        description: `List that can been by all users within the organization`,
    },
    {
        id: '1',
        label: 'By Facility',
        description: 'List that can be seen by all users at the selected facilities.',
    },
    {
        id: '2',
        label: 'By Group',
        description: 'List that can be seen by all users belonging to the selected group(s).',
    },
];
