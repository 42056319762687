export function convertToPrivacyPills(list) {
    let pills = [];

    list.rootCompanies.forEach(c => {
        pills.push({type: 0, companyId: c.id, display: `Organization: ${c.name}`});
    });

    list.facilities.forEach(f => {
        pills.push({type: 1, companyId: f.id, display: f.name});
    });

    list.groups.forEach(g => {
        pills.push({type: 2, companyId: g.groupCompanyId, groupId: g.groupId, display: `${g.companyName} - ${g.groupName}`});
    });

    return pills;
}
