import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getLists} from 'stores/User';
import {
    RadioButton,
    TextField,
    TextFieldSelect,
    Button,
} from '@partssourceinc/react-ui-core';
import usePartsSourceOems from 'hooks/usePartsSourceOems';
import 'less/advancedLists.less';
import 'less/AdvancedLists/adminLists.less';
import axios from 'axios';
import {actionCreators} from 'stores/User';
import AddAssetId from './AddAssetId';
import {useHistory, useParams} from 'react-router-dom';
import UploadFiles from '../../components/UploadFiles';
import PrivacyPillDialog from 'pages/AdvancedLists/PrivacyPillDialog';
import {convertToPrivacyPills} from 'pages/AdvancedLists/advancedListUtility';

export default function AdminSaveAdvancedList(props) {
    const {saveAdminList} = actionCreators;
    const history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();

    const {oems} = useSelector((state) => ({
        oems: state.companies.oems,
    }));

    const partsSourceOems = usePartsSourceOems();
    const [showErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setListName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedList, setSelectedList] = useState(null);
    const [selectedPrivacy, setSelectedPrivacy] = useState(null);
    const [models, setModels] = useState([]);
    const [oem, setSelectedOem] = useState();
    const [model, setSelectedModel] = useState();
    const [assetIds, setAssetIds] = useState(['']);
    const [attachments, setAttachments] = useState([]);
    const [isEditMode] = useState(id != null && id !== undefined);

    const [list, setList] = useState({});
    const [privacyPills, setPrivacyPills] = useState([]);
    const [showPrivacyPillDialog, setShowPrivacyPillDialog] = useState(false);

    useEffect(() => {
        fetchListDetail();
    }, []);

    function fetchListDetail() {
        if (isEditMode) {
            axios
                .get(`ShoppingService/api/v1/lists/admin/list/${id}`)
                .then((x) => {
                    const {name, description, type, equipmentInfo, files, privacy} = x.data;

                    setListName(name);
                    setDescription(description);
                    setSelectedList(type);
                    setSelectedPrivacy(privacy)

                    if (equipmentInfo) {
                        setSelectedOem(equipmentInfo.oem);
                        let _models = [];
                        if (equipmentInfo && equipmentInfo.oem) {
                            axios.get(`CatalogService/api/v1/${equipmentInfo.oem.id}/models`).then((x) => {
                                if (!x.data.some((x) => x.modelId === -1)) {
                                    _models = [{modelId: -1, model: '(MODEL NOT LISTED)'}, ...x.data];
                                }
                                setModels(_models);

                                if (equipmentInfo.model != null) {
                                    let _model = {
                                        modelId: equipmentInfo.model.id,
                                        model: equipmentInfo.model.name
                                    }
                                    setSelectedModel(_model);
                                }
                            });
                        }

                        setAssetIds(equipmentInfo.assetIds.length > 0 ? equipmentInfo.assetIds : ['']);
                        setAttachments(files);
                    }

                    setList(x.data);
                    setPrivacyPills(convertToPrivacyPills(x.data));
                });
        }
    }

    const handleOnChangeAssetId = (val, index) => {
        let data = [...assetIds];
        data[index] = val;
        setAssetIds(data);
    };

    const addNew = () => {
        if (!assetIds.some((x) => !x)) {
            setAssetIds([...assetIds, '']);
        }
    };

    const handleOnSave = useCallback(() => {
        setLoading(true);
        list.name = name;
        list.description = description;model: model ? {id: model.modelId, name: model.model} : null,
        list.type = parseInt(selectedList);
        list.equipmentInfo = {
            oem: oem ? {id: oem.id, name: oem.name} : null,
            model: model ? {id: model.modelId, name: model.model} : null,
            assetIds: assetIds.filter((x) => x !== ''),
        };
        list.files = attachments;

        dispatch(saveAdminList(list)).then(() => {
            setLoading(false);
            history.push('/admin/lists');
        });
    });

    const handleOnSelecteOEM = (item) => {
        let _models = [];

        axios.get(`CatalogService/api/v1/${item.id}/models`).then((x) => {
            if (!x.data.some((x) => x.modelId === -1)) {
                _models = [{modelId: -1, model: '(MODEL NOT LISTED)'}, ...x.data];
            }
            setSelectedOem(item);
            setSelectedModel(null);
            setModels(_models);
        });
    };

    const onAddPrivacyPills = (companyList, facilityList, groupList) => {
        list.rootCompanies = _.uniqWith([...new Set([...list.rootCompanies || [] ,...companyList])], _.isEqual);
        list.facilities = _.uniqWith([...new Set([...list.facilities || [] ,...facilityList])], _.isEqual);
        list.groups = _.uniqWith([...new Set([...list.groups || [] ,...groupList])], _.isEqual);

        setList(list);
        setPrivacyPills(convertToPrivacyPills(list));
        setShowPrivacyPillDialog(false);
    };

    const onRemovePrivacyPill = (pill) => {
        switch (pill.type) {
            case 0:
                list.rootCompanies = list.rootCompanies.filter(x => x.id !== pill.companyId);
                break;
            case 1:
                list.facilities = list.facilities.filter(x => x.id !== pill.companyId);
                break;
            case 2:
                list.groups = list.groups.filter(x => x.groupCompanyId !== pill.groupCompanyId && x.groupId !== pill.groupId);
                break;
        }

        setList(list);
        setPrivacyPills(convertToPrivacyPills(list));
    };

    return (
        <div className="create-advanced-list container adv-admin-list">
            <img src={`/images/${isEditMode ? 'icn_edit.png ' : 'icn_create.png'}`} className={`icon ${isEditMode ? 'icon_edit' : ''} hidden-xs`} />

            <h1 className="title" style={{textAlign: 'center'}}>
                {' '}
                {isEditMode ? 'Edit' : 'Create New'} List{' '}
            </h1>
            <TextField
                label="List Name"
                placeholder="New List Name"
                onChange={(e) => setListName(e.target.value)}
                className="list-name"
                text={name}
                showErrorMessage={showErrors && !name}
                tabIndex={0} />
            <hr className="separator" />
            <div style={{width: '390px'}}>
                <h1 className="subtitle" style={{width: '390px'}}>Availability:<span className="add-availability-pill" onClick={() => setShowPrivacyPillDialog(true)}>+ ADD PRIVACY FILTER</span></h1>
                <div className="availability-wrapper">
                    {privacyPills.map(p => <div className="display-pill">{p.display}<i className="fa fa-times-circle" aria-hidden="true" onClick={() => onRemovePrivacyPill(p)} /></div>)}
                    {privacyPills.length === 0 && <div className="no-pills">This list is currently available to all users.</div>}
                </div>
            </div>
            <hr className="separator" />
            <TextField
                id="description"
                className="list-description"
                multiLine={true}
                rows={4}
                text={description}
                required={true}
                label="Description"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                showErrorMessage={showErrors && !description} />
            <hr className="separator" />
            <div>
                <h1 className="subtitle">List Type:</h1>
                {options.map((item, i) => (
                    <RadioButton
                        key={i}
                        id={`option-${i}`}
                        className="list-radio"
                        value={item.id}
                        onChange={(e) => setSelectedList(e.target.value)}
                        checked={item.id == selectedList}>
                        <span className="list-item-title">{item.label}</span>
                        <div>
                            <span className="list-item-description">{item.description}</span>
                        </div>
                    </RadioButton>
                ))}
            </div>
            {1 === 2 && <hr className="separator" />}
            {1 === 2 && <div>
                <h1 className="subtitle">Add Equipment (optional) </h1>
                <TextFieldSelect
                    id="oemId"
                    keyField="id"
                    valueField="name"
                    className="selectOem"
                    listItems={oems.length === 0 ? partsSourceOems : oems}
                    alwaysShowKey={'26799'}
                    label="OEM"
                    placeHolder="OEM"
                    onSelect={(oem) => handleOnSelecteOEM({id: oem.id, name: oem.name})}
                    showErrorMessage={showErrors && !oem.oemId}
                    selectedItem={oem}
                    required={true}
                    disableClear={true}
                    tabIndex={0}
                />
                <TextFieldSelect
                    id="modelNumber"
                    keyField="modelId"
                    valueField="model"
                    className="selectModel"
                    alwaysShowKey={'-1'}
                    listItems={models}
                    label="Model Number"
                    placeHolder="Model Number"
                    onSelect={(model) => setSelectedModel(model)}
                    showErrorMessage={showErrors && !model.model}
                    submitDisabled={!oem ? true : undefined}
                    selectedItem={model}
                    allowCreate={false}
                    required={true}
                    disableClear={true}
                    tabIndex={0}
                />
                <div style={{marginBottom: 30}}>
                    <AddAssetId elements={assetIds} handleOnChangeAssetId={handleOnChangeAssetId} addNew={addNew} />
                </div>
                {1 === 2 && <UploadFiles setAttachments={setAttachments} attachments={attachments} showRemoval={true} />}
            </div>
            }

            <hr className="separator" />
            <div>
                <div className="actions" style={{marginBottom: 30, width: 390}}>
                    <a className="cancel" onClick={() => history.push('/admin/lists')}>Cancel</a>
                    <div className="col-xs-12 d-block d-sm-none" style={{paddingLeft: '10%', paddingRight: '10%', marginTop: 30}}>
                        <div className="row">
                            <div className="col-xs-5">
                                <hr />
                            </div>
                            <div className="col-xs-2 or">OR</div>
                            <div className="col-xs-5">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <Button
                        className="btnSave"
                        disabled={!name || !description || selectedList === null}
                        onClick={handleOnSave}
                        loading={loading}
                    >Save</Button>
                </div>
            </div>
            {showPrivacyPillDialog && <PrivacyPillDialog onCancel={() => setShowPrivacyPillDialog(false)} onConfirm={onAddPrivacyPills} />}
        </div>
    );
}

const options = [
    {
        id: 0,
        label: 'Shopping List',
        description: 'General list for items you are shopping for.',
    },
    {
        id: 1,
        label: 'Repair List',
        description: 'Items needed for a specific repair on equipment.',
    },
    {
        id: 2,
        label: 'Preventative Maintanance List',
        description:
      'Items used periodically for preventative maintanence on equipment.',
    },
    {
        id: 3,
        label: 'Restocking List',
        description: 'Repeat orders to restock stockrooms or general supplies.',
    },
];
